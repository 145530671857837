import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CreateLeadDto } from 'models/lead.model';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';
import { MdPerson, MdPersonAdd } from 'react-icons/md';
import {
  corrigirFormatoData,
  extrairDigitoVerificador,
  gerarDataAleatoria,
  gerarEmailAleatorio,
  isCPFValid,
  isPhoneValid,
  RgAleatorio,
} from '../../../helpers/lead.helpers';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from '../../../components/icons/IconBox';
import UploadFile from './UploadFile';
import { welfareRobotImportMap } from '../welfareRobotImport.dto';

export interface IModalCreateBatch {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onUpload: (leads: Array<CreateLeadDto>) => void;
}

export default function ModalWelfareCreateBatch({ isOpen, onClose, onUpload }: IModalCreateBatch) {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const {
   loadingUpdate
  } = useAppSelector((state: RootState) => state.welfareRobot);
  const [file, setFile] = useState<string>(null);
  const [data, setData] = useState<Array<any>>([]);
  const [fileLeads, setFileLeads] = useState<Array<CreateLeadDto>>([]);
  const [missingColumns, setMissingColumns] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const missing: string[] = [];
    if (data && data.length) {
      const firstRow = data[0]; // Primeira linha como referência
      Object.values(welfareRobotImportMap).forEach(({ column, defaultValue }) => {
        if (!(firstRow.includes(column)) && !defaultValue) {
          missing.push(column);
        }
      });
    }
    setMissingColumns(missing);
  }, [data]);

  useEffect(() => {
    if (data && data.length) {
      const firstRow = data[0]; // Primeira linha como cabeçalhos
      const indices: Record<string, number> = {};

      // Identificar os índices das colunas com base no cabeçalho
      Object.entries(welfareRobotImportMap).forEach(([key, { column }]) => {
        const index = firstRow.findIndex((header: string) => header?.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "") === column.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ""));
        if (index !== -1) {
          indices[key] = index;
        }
      });

      const newLeads: Array<CreateLeadDto> = [];

      // Processar as linhas restantes (dados)
      data.slice(1).forEach((row) => {
        const lead = new CreateLeadDto();
        const cpf = isCPFValid(row[indices['cpf']] || '');
        // const phoneNumber = isPhoneValid(row[indices['phone']] || '');
        // const name = String(row[indices['name']] || '').trim();

        if (cpf/* && phoneNumber && name*/) {
          Object.entries(welfareRobotImportMap).forEach(([key, { defaultValue }]) => {
            const columnKey = key as keyof CreateLeadDto;
            const value = row[indices[key]];

            switch (columnKey) {
              // case 'name':
              //   lead[columnKey] = name;
              //   break;
              case 'cpf':
                lead[columnKey] = cpf;
                break;
              // case 'phone':
              //   lead[columnKey] = phoneNumber;
              //   break;
              case 'banco':
                lead[columnKey] = value || '';
                break;
              case 'estadoCivil':
              case 'nacionalidade':
              case 'valorPatrimonio':
              case 'numero':
                lead[columnKey] = Number(value) || defaultValue || 111;
                break;
              case 'agencia':
                lead[columnKey] = value ? Number(value.replace(/\D/g, '')) : 0;
                break
              case 'dataNascimento':
                lead[columnKey] = value ? corrigirFormatoData(value) : defaultValue;
                break;
              case 'dataExpedicao':
                lead[columnKey] = gerarDataAleatoria(corrigirFormatoData(row[indices['dataNascimento']]));
                break;
              case 'cep':
                lead[columnKey] = value ? value.replace(/\D/g, '').padStart(8, '0') : defaultValue;
                break;
              case 'conta':
                lead[columnKey] = value ? value.replace(/\D/g, '') : '';
                break;
              case 'rg':
                lead[columnKey] = value ? value.replace(/\D/g, '') : RgAleatorio();
                break;
              case 'estadoRg':
              case 'estadoNatural':
                lead[columnKey] = value || row[indices['estado']];
                break;
              case 'cidadeNatural':
                lead[columnKey] = value || row[indices['cidade']];
                break;
              case 'complemento':
                lead[columnKey] = value || '';
                break;
              case 'contaDigito':
                lead[columnKey] = extrairDigitoVerificador(row[indices['conta']]) || row[indices['contaDigito']] || '';
                break;
              case 'tipoConta':
                lead[columnKey] = row[indices['banco']] !== '104' ? 'CONTA_CORRENTE' : 'CONTA_POUPANCA';
                break;
              case 'email':
                lead[columnKey] = row[indices['email']] || gerarEmailAleatorio(row[indices['name']] || '');
                break;
              default:
                // @ts-ignore
                lead[columnKey] = value || defaultValue;
                break;
            }
          });

          newLeads.push(lead);
        }
      });

      console.log(newLeads); // Para verificar os leads processados
      setFileLeads(newLeads);
      setLoading(false);
    }
  }, [data]);


  function closeModal() {
    setData([]);
    setFileLeads([]);
    onUpload(fileLeads);
    onClose();
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={closeModal}
      size={'2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Importar base para digitação</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <UploadFile
              onFiles={(f) => setFile(f)}
              onData={(data) => setData(data)}
              onLoading={(b) => setLoading(b)}
            />
            {loading ? <Progress size='xs' width={'100%'} isIndeterminate />: null}
            {missingColumns.length ? <Alert status='error'>
                <AlertIcon />
              <Box>
                <AlertTitle>Estão faltando as seguintes informações:</AlertTitle>
                <AlertDescription>
                  {missingColumns.join(', ')}
                </AlertDescription>
              </Box>
            </Alert> : null}
            {!loading && data.length ? <HStack mb='20px'>
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={'secondaryGray.300'}
                    icon={<Icon w='32px' h='32px' as={MdPerson} color={'brand.500'} />}
                  />
                }
                name='Linhas do arquivo'
                value={data.length}
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={'brand.500'}
                    icon={<Icon w='32px' h='32px' as={MdPersonAdd} color={'secondaryGray.300'} />}
                  />
                }
                name='Leads válidos'
                value={fileLeads.length}
              />
            </HStack> : null}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} colorScheme='blue' size={'md'} isLoading={loadingUpdate}
                  isDisabled={fileLeads.length === 0 || missingColumns.length > 0}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
