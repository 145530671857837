import { Icon } from '@chakra-ui/react';
import { MdOutlineMessage, MdPerson } from 'react-icons/md';
import Leads from 'views/leads';
import Channels from './views/channels';
import Campaings from './views/campaings';
import { FaChartLine, FaRobot, FaWhatsapp } from 'react-icons/fa6';
import TypingRobots from './views/typingRobots';
import Indicators from './views/indicators';
import WelfareRobots from './views/welfareRobots';

const routes = [
	/* {
		name: 'Main Dashboard',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'NFT Marketplace',
		layout: '/admin',
		path: '/nft-marketplace',
		icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
		component: NFTMarketplace,
		secondary: true
	}, */
	{
		name: 'Indicadores',
		layout: '/admin',
		icon: <Icon as={FaChartLine} width='20px' height='20px' color='inherit' />,
		path: '/indicators',
		component: Indicators,
	},
	{
		name: 'Leads',
		layout: '/admin',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		path: '/leads',
		component: Leads,
	},
	// {
	// 	name: 'Robôs',
	// 	layout: '/admin',
	// 	icon: <Icon as={FaRobot} width='20px' height='20px' color='inherit' />,
	// 	path: '/robots',
	// 	component: Robots,
	// },
/*	{
		name: 'Robôs Consulta',
		layout: '/admin',
		icon: <Icon as={FaRobot} width='20px' height='20px' color='inherit' />,
		path: '/balance-robots',
		component: BalanceRobots,
	},*/
	{
		name: 'Robôs FGTS',
		layout: '/admin',
		icon: <Icon as={FaRobot} width='20px' height='20px' color='inherit' />,
		path: '/typing-robots',
		component: TypingRobots,
	},
	{
		name: 'Robôs Auxílio',
		layout: '/admin',
		icon: <Icon as={FaRobot} width='20px' height='20px' color='inherit' />,
		path: '/welfare-robots',
		component: WelfareRobots,
	},
	{
		name: 'Canais',
		layout: '/admin',
		icon: <Icon as={FaWhatsapp} width='20px' height='20px' color='inherit' />,
		path: '/channels',
		component: Channels,
	},
	{
		name: 'Campanhas',
		layout: '/admin',
		icon: <Icon as={MdOutlineMessage} width='20px' height='20px' color='inherit' />,
		path: '/campains',
		component: Campaings,
	},
/*	{
		name: 'Robôs Higienização',
		layout: '/admin',
		icon: <Icon as={FaRobot} width='20px' height='20px' color='inherit' />,
		path: '/cleaning-robots',
		component: CleaningRobots,
	},*/
/*	{
		name: 'URA reversa',
		layout: '/admin',
		icon: <Icon as={FaPhoneFlip} width='20px' height='20px' color='inherit' />,
		path: '/voice-campaing',
		component: VoiceCampaings,
	}*/
	/* {
		name: 'Profile',
		layout: '/admin',
		path: '/profile',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: Profile
	}, */
	/* {
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignInCentered
	}, */
/* 	{
		name: 'RTL Admin',
		layout: '/rtl',
		path: '/rtl-default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: RTL
	}, */
];

export default routes;
