import { Button, FormControl, Tag } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import * as React from 'react';
import { OptionItemType } from '../filter/filter.constants';

export function LeadTagSelector(
  {
    options,
    onChange,
    value,
    onNewTag,
  }: {
    label: string,
    options: Array<OptionItemType>
    onChange?: (v: OptionItemType) => void,
    value?: OptionItemType;
    onNewTag: (v: string) => void;
  },
) {

  function change(values: any) {
    onChange(values);
  }

  return (
    <FormControl>
      <Select
        isMulti={false}
        options={options}
        placeholder='Selecione ou digite pare criar nova'
        closeMenuOnSelect={true}
        onChange={change}
        noOptionsMessage={(v) => (
          v.inputValue ? <Button variant='link' onClick={() => onNewTag(v.inputValue)} size={'sm'}>
            Criar
            <Tag ml={2} variant='solid' colorScheme='teal'>
              {v.inputValue}
            </Tag>
          </Button> : null
        )}
        value={value}
      />
    </FormControl>
  );
}
