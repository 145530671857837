import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wesendApi } from '../../../services/api/wesend.api';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { RobotFilterInput, RobotStatusType } from '../../../models/robot.model';
import { CreateLeadDto, ImportLeadResponseDto } from '../../../models/lead.model';
import { getCompanySubscription } from '../company/company.slice';
import { WelfareRobot } from '../../../models/welfareRobot.model';

export interface WelfareRobotsState {
  robots: Array<WelfareRobot>;
  loading: boolean;
  loadingUpdate: boolean;
  error: string | null;
  loadingImporting: boolean;
  pagination: PaginatedMetaData | undefined;
  importPercent: number;
  importResult: ImportLeadResponseDto | null;
}

const initialState: WelfareRobotsState = {
  robots: [],
  loading: false,
  loadingImporting: false,
  importResult: null,
  importPercent: 0,
  error: null,
  loadingUpdate: false,
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const getWelfareRobots = createAsyncThunk<{ robots: Array<WelfareRobot>, pagination: PaginatedMetaData },{ page: number, filter: RobotFilterInput}>(
  'welfareRobot/list',
  async (params, {dispatch, getState}) => {
    const robots = await wesendApi.welfareRobot.list({
      ...params.filter,
      page: params.page,
    });
    return { robots: robots.data, pagination: robots.meta };
  },
);

export const refreshWelfareRobots = createAsyncThunk<{ robots: Array<WelfareRobot>, pagination: PaginatedMetaData },{ page: number, filter: RobotFilterInput}>(
  'welfareRobot/refresh',
  async (params, {dispatch, getState}) => {
    const robots = await wesendApi.welfareRobot.list({
      ...params.filter,
      page: params.page,
    });
    return { robots: robots.data, pagination: robots.meta };
  },
);

export const removeWelfareRobot = createAsyncThunk<{ success: boolean },{ id: string }>(
  'welfareRobot/remove',
  async (params, {dispatch, getState}) => {
    await wesendApi.welfareRobot.delete(params.id);
    dispatch(getCompanySubscription());
    return { success: true };
  },
);

export const changeWelfareRobotStatus = createAsyncThunk<{ success: boolean },{ id: string, status: RobotStatusType }>(
  'welfareRobot/changeStatus',
  async (params, {dispatch, getState}) => {
    await wesendApi.welfareRobot.changeWelfareRobotStatus(params.id, params.status);
    return { success: true };
  },
);

export const createWelfareRobot = createAsyncThunk<WelfareRobot, WelfareRobot>(
  'welfareRobot/create',
  async (robot, {dispatch, getState}) => {
    dispatch(getCompanySubscription());
    return await wesendApi.welfareRobot.create(robot);
  },
);

export const editWelfareRobot = createAsyncThunk<WelfareRobot, WelfareRobot>(
  'welfareRobot/edit',
  async (robot, {dispatch, getState}) => {
    return await wesendApi.welfareRobot.editWelfareRobot(robot);
  },
);

export const createWelfareBatchCampaing = createAsyncThunk<string, { id: string, leads: Array<CreateLeadDto>}>(
  'welfareRobot/createBatchCampaing',
  async (_, {dispatch, getState}) => {
    return await wesendApi.welfareRobot.createBatchWelfareRobot(_.id, _.leads);
  },
);

export const exportWelfareBatch = createAsyncThunk<ArrayBuffer, string>(
  'welfareRobot/exportCampaingBatch',
  async (id, {dispatch, getState}) => {
    const csv = await wesendApi.welfareRobot.exportBatch(id);
    return csv;
  },
);

export const getModelData = createAsyncThunk<string>(
  'welfareRobot/getModelData',
  async (id, {dispatch, getState}) => {
    const csv = await wesendApi.welfareRobot.getModelData();
    return csv;
  },
);

export const getImportStatus = createAsyncThunk<ImportLeadResponseDto, string>(
  'welfareRobot/importStatus',
  async (importId, { getState }) => {
    return await wesendApi.lead.importStatus(importId);
  });

export const welfareRobotsSlice = createSlice({
  name: 'WelfareRobot',
  initialState,
  reducers: {
    clearWelfareImportationResult: (state) => {
      state.importResult = null;
      state.loadingImporting = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWelfareRobots.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWelfareRobots.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.robots = action.payload.robots;
        state.pagination = action.payload.pagination;
      })
      .addCase(getWelfareRobots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(refreshWelfareRobots.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.robots = action.payload.robots;
        state.pagination = action.payload.pagination;
      })
      .addCase(refreshWelfareRobots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeWelfareRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(removeWelfareRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(removeWelfareRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(changeWelfareRobotStatus.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(changeWelfareRobotStatus.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(changeWelfareRobotStatus.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createWelfareRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(createWelfareRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createWelfareRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(editWelfareRobot.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(editWelfareRobot.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(editWelfareRobot.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createWelfareBatchCampaing.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(createWelfareBatchCampaing.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createWelfareBatchCampaing.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(exportWelfareBatch.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(exportWelfareBatch.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(exportWelfareBatch.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(getModelData.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(getModelData.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(getModelData.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(getImportStatus.pending, (state) => {
        state.loadingImporting = true;
      })
      .addCase(getImportStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingImporting = false;
      })
      .addCase(getImportStatus.fulfilled, (state, action) => {
        state.error = null;
        state.importPercent = parseInt(String(action.payload.completed / action.payload.size * 100), 10);
        if (state.importPercent === 100) {
          state.importResult = action.payload;
          state.loadingImporting = false;
        }
      })
  },
});
export const {clearWelfareImportationResult} = welfareRobotsSlice.actions;
