import WesendRequests from './wesend.requests';
import { login, testToken } from './auth/auth.wesend.api';
import {
  editMany,
  editManyWithCpf,
  exportLeads,
  getLeads,
  importLeads,
  importStatus,
  removeLead,
  removeMany,
  updateLead,
} from './lead/lead.wesend.api';
import axios from 'axios';
import {
  changeStatus,
  createBatchRobot,
  createRobot,
  editRobot,
  exportBatch,
  getRobots,
  removeRobot,
} from './robot/robot.wesend.api';
import {
  createChannel,
  editChannel,
  getChannel,
  getChannels,
  removeChannel,
  restartChannel,
} from './channel/channel.wesend.api';
import {
  changeTypingRobotStatus,
  createBatchTypingRobot,
  createTypingRobot,
  editTypingRobot,
  exportTypingBatch,
  getModelData,
  getTypingRobots,
  removeTypingRobot,
} from './typingRobot/typingRobot.wesend.api';
import {
  createBatchVoiceCampaign,
  createVoiceCampaign,
  exportVoiceCampaignBatch,
  getVoiceCampaigns,
  stopVoiceCampaign,
} from './campaign/voiceCampaign.wesend.api';
import {
  createBatchCampaing,
  createCampaign,
  exportCampaingBatch,
  getCampaigns,
  stopCampaign,
} from './voiceCampaign/campaign.wesend.api';
import { getCompanyIndicators, getCompanySubscription } from './company/company.wesend.api';
import {
  changeCleaningRobotStatus,
  createBatchCleaningRobot,
  createCleaningRobot,
  editCleaningRobot,
  exportCleaningRobotBatch,
  getCleaningRobots,
  removeCleaningRobot,
} from './cleaningRobot/cleaningRobot.wesend.api';
import {
  createIntegration,
  editIntegration,
  getIntegrations,
  removeIntegration,
} from './integrationAuth/integrationAuth.wesend.api';
import { createLeadTag, getAllLeadTags } from './leadTag/leadTag.wesend.api';
import {
  changeWelfareRobotStatus, createBatchWelfareRobot,
  createWelfareRobot,
  editWelfareRobot, exportWelfareBatch,
  getWelfareRobots,
  removeWelfareRobot,
} from './welfareRobot/welfareRobot.wesend.api';

const token = localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export const wesendRequests = new WesendRequests();

export const wesendApi = {
  logout,
  // setToken: wesendRequests.setToken,
  login,
  testToken,
  lead: {
    list: getLeads,
    import: importLeads,
    importStatus,
    export: exportLeads,
    update: updateLead,
    remove: removeLead,
    removeMany,
    editMany,
    editManyWithCpf,
  },
  channel: {
    get: getChannel,
    list: getChannels,
    delete: removeChannel,
    create: createChannel,
    edit: editChannel,
    restart: restartChannel,
  },
  robot: {
    list: getRobots,
    delete: removeRobot,
    create: createRobot,
    editRobot,
    changeStatus,
    createBatchRobot,
    exportBatch,
  },
  typingRobot: {
    list: getTypingRobots,
    delete: removeTypingRobot,
    create: createTypingRobot,
    editTypingRobot,
    changeTypingRobotStatus,
    createBatchTypingRobot,
    exportBatch: exportTypingBatch,
    getModelData,
  },
  welfareRobot: {
    list: getWelfareRobots,
    delete: removeWelfareRobot,
    create: createWelfareRobot,
    editWelfareRobot,
    changeWelfareRobotStatus,
    createBatchWelfareRobot,
    exportBatch: exportWelfareBatch,
    getModelData,
  },
  campaign: {
    create: createCampaign,
    list: getCampaigns,
    stop: stopCampaign,
    createBatchCampaing,
    exportCampaingBatch,
  },
  voiceCampaign: {
    create: createVoiceCampaign,
    list: getVoiceCampaigns,
    stop: stopVoiceCampaign,
    createBatchVoiceCampaign,
    exportVoiceCampaignBatch,
  },
  company: {
    getCompanySubscription,
    getCompanyIndicators,
  },
  cleaningRobot: {
    list: getCleaningRobots,
    delete: removeCleaningRobot,
    create: createCleaningRobot,
    edit: editCleaningRobot,
    changeStatus: changeCleaningRobotStatus,
    createBatchRobot: createBatchCleaningRobot,
    exportBatch: exportCleaningRobotBatch,
  },
  integration: {
    create: createIntegration,
    edit: editIntegration,
    delete: removeIntegration,
    list: getIntegrations,
  },
  leadTag: {
    create: createLeadTag,
    getAll: getAllLeadTags,
  }
}

export function logout() {
  localStorage.clear();
  // @ts-ignore
  window.location = '/';
}
