import { wesendRequests } from '../wesend.api';
import { CreateLeadTag, LeadTag } from '../../../models/leadTag.model';

export const createLeadTag = async (dto: CreateLeadTag): Promise<string> => {
  try {
    const path = `leadTag`;
    const result = await wesendRequests.post(path, dto);
    return result;
  } catch (e) {
    console.log('createLeadTag', e)
    throw e;
  }
};

export const getAllLeadTags = async (): Promise<Array<LeadTag>> => {
  try {
    const path = `leadTag`;
    const result = await wesendRequests.get(path, {});
    return result;
  } catch (e) {
    console.log('getAllLeadTags', e)
    throw e;
  }
};
