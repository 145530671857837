import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { leadsSlice } from './features/leads/leads.api';
import { authSlice } from './features/auth/auth.slice';
import { channelSlice } from './features/channel/channel.slice';
import { campaingSlice } from './features/campaing/campaing.slice';
import { robotSlice } from './features/robots/robots.slice';
import { typingRobotsSlice } from './features/typingRobots/typingRobots.slice';
import { voiceCampaignSlice } from './features/voiceCampaing/voiceCampaign.slice';
import { companySlice } from './features/company/company.slice';
import { cleaningRobotSlice } from './features/cleaningRobots/cleaningRobots.slice';
import { integrationSlice } from './features/integration/integration.slice';
import { leadTagSlice } from './features/leadTag/leadTag.slice';
import { welfareRobotsSlice } from './features/welfareRobots/welfareRobots.slice';

export const store = configureStore({
  reducer: combineReducers({
    auth: authSlice.reducer,
    leads: leadsSlice.reducer,
    channel: channelSlice.reducer,
    campaing: campaingSlice.reducer,
    robot: robotSlice.reducer,
    typingRobot: typingRobotsSlice.reducer,
    voiceCampaing: voiceCampaignSlice.reducer,
    company: companySlice.reducer,
    cleaningRobot: cleaningRobotSlice.reducer,
    welfareRobot: welfareRobotsSlice.reducer,
    integration: integrationSlice.reducer,
    leadTag: leadTagSlice.reducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
