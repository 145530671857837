import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wesendApi } from '../../../services/api/wesend.api';
import { CreateLeadTag, LeadTag } from '../../../models/leadTag.model';

export interface LeadTagState {
  loading: boolean;
  error: string | null;
  tags: Array<LeadTag>,
}

const initialState: LeadTagState = {
  loading: false,
  error: null,
  tags: [],
};

export const createLeadTag = createAsyncThunk<string, CreateLeadTag>(
  'leadTag/create',
  async (data, { getState, dispatch }) => {
    const result = await wesendApi.leadTag.create(data);
    dispatch(getAllLeadTags());
    return result;
  });

export const getAllLeadTags = createAsyncThunk<Array<LeadTag>>(
  'leadTag/getAll',
  async (id, { getState, dispatch }) => {
    return await wesendApi.leadTag.getAll();
  });

export const leadTagSlice = createSlice({
  name: 'LeadTag',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeadTags.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLeadTags.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.tags = action.payload;
      })
      .addCase(getAllLeadTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createLeadTag.pending, (state) => {
        state.loading = true;
      })
      .addCase(createLeadTag.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(createLeadTag.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});
