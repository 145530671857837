import { ProductType, ProductTypeName } from '../../models/robot.model';
import { Avatar, Tooltip } from '@chakra-ui/react';

const ProductIcons = {
  [ProductType.FGTS]: 'fgts.png',
  [ProductType.BOLSA_FAMILIA]: 'bolsa-familia.png',
};

export interface IProductIcon {
  product: ProductType,
}

export default function ProductIcon({ product }: IProductIcon) {
  return (
    <Tooltip label={ProductTypeName[product]} fontSize='smaller'>
      <Avatar mx='auto' src={`/product/${ProductIcons[product]}`} h='35px' w='35px'/>
     </Tooltip>
  );
}
