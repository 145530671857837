import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ChangeManyLeadsDto,
  EditManyLeadsDto,
  EditManyLeadsWithCpfDto,
  ExportAndEditLeadsDto,
  ImportLeadResponseDto,
  ImportLeadsDto,
  Lead,
  UpdateLeadDto,
} from '../../../models/lead.model';
import { showToast } from '../../../services/toast.service';
import { wesendApi } from '../../../services/api/wesend.api';
import { LeadFilterInput, PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';

export interface LeadsState {
  leads: Array<Lead>;
  loading: boolean;
  error: string | null;
  filters: LeadFilterInput | undefined;
  loadingUpdate: boolean;
  pagination: PaginatedMetaData | undefined;
  result: ImportLeadResponseDto | null;
  importing: boolean;
  importPercent: number;
}

const initialState: LeadsState = {
  leads: [],
  loading: false,
  error: null,
  filters: undefined,
  loadingUpdate: false,
  result: null,
  importing: false,
  importPercent: 0,
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const getLeads = createAsyncThunk<{ leads: Array<Lead>, pagination: PaginatedMetaData },{ page: number, filter: LeadFilterInput}>(
  'lead/list',
  async (params, {dispatch, getState}) => {
    const leads = await wesendApi.lead.list({
      ...params.filter,
      page: params.page,
    });
    return { leads: leads.data, pagination: leads.meta };
  },
);

export const updateLead = createAsyncThunk<Lead, UpdateLeadDto>(
  'lead/update',
  async (data, { getState }) => {
    return await wesendApi.lead.update(data);
  });

export const removeLead = createAsyncThunk<boolean, string>(
  'lead/remove',
  async (id, { getState }) => {
    return await wesendApi.lead.remove(id);
  });

export const removeMany = createAsyncThunk<boolean, ChangeManyLeadsDto>(
  'lead/removeMany',
  async (input, { getState }) => {
    return await wesendApi.lead.removeMany(input);
  });

export const editMany = createAsyncThunk<boolean, EditManyLeadsDto>(
  'lead/editMany',
  async (input, { getState }) => {
    return await wesendApi.lead.editMany(input);
  });

export const editManyWithCpf = createAsyncThunk<boolean, EditManyLeadsWithCpfDto>(
  'lead/editManyWithCpf',
  async (input, { getState }) => {
    return await wesendApi.lead.editManyWithCpf(input);
  });

export const importLeads = createAsyncThunk<string, ImportLeadsDto>(
  'lead/import',
  async (data, { getState }) => {
    return await wesendApi.lead.import(data);
  });

export const getImportStatus = createAsyncThunk<ImportLeadResponseDto, string>(
  'lead/importStatus',
  async (importId, { getState }) => {
    return await wesendApi.lead.importStatus(importId);
  });

export const exportLeads = createAsyncThunk<string, ExportAndEditLeadsDto>(
  'lead/export',
  async (params, {dispatch, getState}) => {
    const csv = await wesendApi.lead.export(params);
    return csv;
  },
);

export const leadsSlice = createSlice({
  name: 'Leads',
  initialState,
  reducers: {
    clearResult: (state) => {
      state.result = null;
      state.importing = false;
    },
    setLeads: (state, action) => {
      state.leads = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    initImportation: (state) => {
      state.importing = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(importLeads.pending, (state) => {
        state.result = null;
        state.importing = true;
        state.result = null;
        state.importPercent = 0;
      })
      .addCase(importLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.result = null;
        state.importPercent = 0;
      })
      .addCase(importLeads.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(getImportStatus.pending, (state) => {
        state.result = null;
      })
      .addCase(getImportStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.result = null;
        state.importing = false;
      })
      .addCase(getImportStatus.fulfilled, (state, action) => {
        state.error = null;
        state.importPercent = parseInt(String(action.payload.completed / action.payload.size * 100), 10);
        if (state.importPercent === 100) {
          showToast('success', 'Leads importados!');
          state.result = action.payload;
          state.importing = false;
          state.importPercent = 0;
        }
      })
      .addCase(exportLeads.rejected, (state, action) => {
        state.loading = false;
        state.result = null;
        showToast('error', action.error.message);
      })
      .addCase(exportLeads.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(exportLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeads.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.leads = action.payload.leads;
        state.pagination = action.payload.pagination;
      })
      .addCase(getLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateLead.pending, (state) => {
          state.loadingUpdate = true;
        })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(removeLead.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(removeLead.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(removeLead.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(removeMany.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(removeMany.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(removeMany.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(editMany.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(editMany.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(editMany.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      });
  },
});

export const {clearResult, initImportation} = leadsSlice.actions;
