import { wesendRequests } from '../wesend.api';
import { RobotFilterInput, RobotStatusType } from '../../../models/robot.model';
import { PaginatedResult } from '../lead/lead.dto.wesendapi';
import { WelfareRobot } from '../../../models/welfareRobot.model';
import { CreateLeadDto } from '../../../models/lead.model';

export const getWelfareRobots = async (data: RobotFilterInput & {
  // orderBy?: UserOrderByInput,
  page?: number,
}): Promise<PaginatedResult<WelfareRobot>> => {
  try {
    const path = 'welfareRobot';
    const result = await wesendRequests.get(path, data);
    return result;
  } catch (e) {
    throw e;
  }
};

export const removeWelfareRobot = async (id: string): Promise<boolean> => {
  try {
    const path = `welfareRobot/${id}`;
    const result = await wesendRequests.delete(path);
    return result;
  } catch (e) {
    throw e;
  }
};

export const changeWelfareRobotStatus = async (id: string, status: RobotStatusType): Promise<boolean> => {
  try {
    const path = `welfareRobot/changeStatus/${id}`;
    const result = await wesendRequests.post(path, { status });
    return result;
  } catch (e) {
    throw e;
  }
};

export const createWelfareRobot = async (welfareRobot: WelfareRobot): Promise<WelfareRobot> => {
  try {
    const path = `welfareRobot`;
    const result = await wesendRequests.post(path, welfareRobot);
    return result;
  } catch (e) {
    console.log('createWelfareRobot', e)
    throw e;
  }
};

export const editWelfareRobot = async (welfareRobot: WelfareRobot): Promise<WelfareRobot> => {
  try {
    const path = `welfareRobot/${welfareRobot.id}`;
    const result = await wesendRequests.put(path, welfareRobot);
    return result;
  } catch (e) {
    console.log('createWelfareRobot', e)
    throw e;
  }
};

export const createBatchWelfareRobot = async (welfareRobotId: string, leads: Array<CreateLeadDto>): Promise<string> => {
  try {
    const path = `welfareRobot/importLeads/${welfareRobotId}`;
    const result = await wesendRequests.post(path, leads);
    return result;
  } catch (e) {
    console.log('createWelfareRobot', e)
    throw e;
  }
};

export const exportWelfareBatch = async (welfareRobotId: string): Promise<ArrayBuffer> => {
  try {
    const path = `welfareRobot/exportBatch/${welfareRobotId}`;
    const result = await wesendRequests.get(path, {}, { responseType: 'blob' });
    return result;
  } catch (e) {
    console.log('createWelfareRobot', e)
    throw e;
  }
};

export const getModelData = async (): Promise<string> => {
  try {
    const path = `welfareRobot/getModelData`;
    const result = await wesendRequests.get(path, {});
    return result;
  } catch (e) {
    console.log('getModelData', e)
    throw e;
  }
};

