import {
  Box,
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { LeadFilterInput } from '../../../services/api/lead/lead.dto.wesendapi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  ChangeManyLeadsType,
  LeadMessageStatus,
  LeadProposalStatus,
  LeadSimulationStatus,
  messageStatusName,
  proposalStatusName,
  simulationStatusName,
} from '../../../models/lead.model';
import { useFormik } from 'formik';
import { exportLeads } from '../../../store/features/leads/leads.api';
import { showToast } from '../../../services/toast.service';
import { InputNumber } from '../../../components/inputNumber/inputNumber.component';
import { HSeparator } from '../../../components/separator/Separator';
import { format } from 'date-fns';
import { FaUpload } from 'react-icons/fa6';
import { LeadTagSelector } from '../../../components/leadTagSelector/leadTagSelector';
import { RootState } from '../../../store/store';
import { LeadTag } from '../../../models/leadTag.model';
import { createLeadTag, getAllLeadTags } from '../../../store/features/leadTag/leadTag.slice';
import { OptionItemType } from '../../../components/filter/filter.constants';


interface IExportButtonProps {
  loading: boolean;
  initialLimit: number
  totalCount: number;
  selectedLeads: Array<string>;
  filter: LeadFilterInput;
  onUpdate: () => void;
}

export const ExportButton = ({ loading, initialLimit, selectedLeads, onUpdate, totalCount, filter }: IExportButtonProps) => {

  const dispatch = useAppDispatch();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const initialSelection: ChangeManyLeadsType = selectedLeads.length ? 'selected' : (initialLimit <= 10000 ? 'all' : 'count');
  const [selectionType, setSelectionType] = useState<ChangeManyLeadsType>(initialSelection);
  const [selectedTag, setSelectedTag] = useState<OptionItemType>(null);
  const [count, setCount] = useState(initialLimit);
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { tags, loading: loadingTags, error} = useAppSelector((state: RootState) => state.leadTag);

  const { handleSubmit, handleChange, values, setFieldValue} = useFormik({
    initialValues: {
      lastSimulationStatus: 'NOTHING',
      lastMessageStatus: 'NOTHING',
      lastProposalStatus: 'NOTHING',
      tagId: null,
    },
    onSubmit: async (values) => {
      const result = await dispatch(exportLeads({
        count,
        filter,
        ids: selectedLeads,
        type: selectionType,
        lastMessageStatus: values.lastMessageStatus as LeadMessageStatus & 'NOTHING',
        lastProposalStatus: values.lastProposalStatus as LeadProposalStatus & 'NOTHING',
        lastSimulationStatus: values.lastSimulationStatus as LeadSimulationStatus & 'NOTHING',
        tagId: selectedTag ? selectedTag.value : null,
      }));
      console.log('RESULT', result)
      if (result.payload) {
        showToast('success', 'Leads exportados/editados!');
        const a = document.createElement('a');
        // @ts-ignore
        const blob = new Blob([result.payload], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `[wesend] Leads exportados ${format(new Date(), 'dd/MM/yyyy-HH:mm')}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        onClose();
        onUpdate();
      }
    },
  });

  useEffect(() => {
    setCount(initialLimit);
  }, [initialLimit])

  useEffect(() => {
    setSelectionType(initialSelection);
    setFieldValue('lastMessageStatus', 'NOTHING');
  }, [initialSelection]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getAllLeadTags());
      setSelectedTag(null);
    }
  }, [isOpen]);

  async function createNewTag(name: string) {
    const response: any = await dispatch(createLeadTag({
      name,
    }));
    if (response.payload) {
      setFieldValue('tag', response.payload);
    }
  }

  return (
    <>
      <Button mb={2} mr={3} rightIcon={<FaUpload />} colorScheme='blue' variant='link' onClick={onOpen}>
        Exportar
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Exportar leads</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <RadioGroup onChange={(v) => setSelectionType(v as ChangeManyLeadsType)} value={selectionType}>
                <Stack direction='column' gap={5}>
                  {selectedLeads.length > 0 ?
                    <Radio value='selected'><b>{selectedLeads.length}</b> leads selecionados</Radio> : null }
                  {totalCount <= 100000 ?
                    <Radio value='all'>Todos <b>({totalCount})</b></Radio> : null}
                  <Radio value='count'>Definir quantidade</Radio>
                </Stack>
                {selectionType === 'count' ? <Box mt={5}>
                  <FormControl>
                    <InputNumber min={1}
                                 max={totalCount}
                                 value={count}
                                 onChange={(e) => setCount(e)} />
                  </FormControl>
                </Box> : null}
              </RadioGroup>
              <HSeparator mt={5} mb={5}/>
              <HStack alignItems={'center'} mb={5}>
                <Text as={'b'} textAlign={'right'} w={200}>Última simulação:</Text>
                <Select maxW={300} onChange={handleChange('lastSimulationStatus')}
                        value={values?.lastSimulationStatus}>
                  <option value={'NOTHING'}>Não alterar</option>
                  {Object.values(LeadSimulationStatus).map(s =>
                    <option value={s}>{simulationStatusName[s]}</option>
                  )}
                </Select>
              </HStack>
              <HStack alignItems={'center'} mb={5}>
                <Text as={'b'} textAlign={'right'} w={200}>Última mensagem:</Text>
                <Select maxW={300} onChange={handleChange('lastMessageStatus')}
                        value={values?.lastMessageStatus}>
                  <option value={'NOTHING'}>Não alterar</option>
                  {Object.values(LeadMessageStatus).map(s =>
                    <option value={s}>{messageStatusName[s]}</option>
                  )}
                </Select>
              </HStack>
              <HStack alignItems={'center'}>
                <Text as={'b'} textAlign={'right'} w={200}>Última proposta:</Text>
                <Select maxW={300} onChange={handleChange('lastProposalStatus')}
                        value={values?.lastProposalStatus}>
                  <option value={'NOTHING'}>Não alterar</option>
                  {Object.values(LeadProposalStatus).map(s =>
                    <option value={s}>{proposalStatusName[s]}</option>
                  )}
                </Select>
              </HStack>
              <HStack alignItems={'center'} mt={5}>
                <Text as={'b'} textAlign={'right'} w={200}>Tag:</Text>
                <LeadTagSelector
                  options={tags.map((t: LeadTag) => {
                    return {
                      label: t.name,
                      value: t.id,
                      colorScheme: 'blue',
                    };
                  })}
                  label={'asd'}
                  value={selectedTag}
                  onChange={(v) => setSelectedTag(v)}
                  onNewTag={(n) => createNewTag(n)}
                />
              </HStack>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancelar</Button>
            <Button colorScheme='green' size={'md'} isLoading={loading} onClick={() => handleSubmit()}>
              Exportar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
