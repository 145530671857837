import { BankType, BankTypeName } from '../../models/robot.model';
import { Avatar, Tooltip } from '@chakra-ui/react';

const BankIcons = {
  [BankType.C6]: 'c6.png',
  [BankType.FACTA]: 'facta.png',
  [BankType.PARANA]: 'parana.png',
  [BankType.CREFISA]: 'crefisa.png',
};

export interface IBankIcon {
  bank: BankType,
}

export default function BankIcon({ bank }: IBankIcon) {
  return (
    // <Tooltip label={BankTypeName[bank]} fontSize='sm'>
      <Avatar mx='auto' src={`/bank/${BankIcons[bank]}`} h='25px' w='25px'/>
    // </Tooltip>
  );
}
