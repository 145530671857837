import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import ConfirmationButton from '../../../components/buttons/RemoveConfirmation';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import {
  LeadMessageStatus,
  LeadProposalStatus,
  LeadSimulationStatus,
  messageStatusName,
  proposalStatusName,
  simulationStatusName,
} from '../../../models/lead.model';
import { useFormik } from 'formik';
import { VSeparator } from '../../../components/separator/Separator';
import MiniStatistics from '../../../components/card/MiniStatistics';
import IconBox from '../../../components/icons/IconBox';
import { MdPerson } from 'react-icons/md';
import { isCPFValid } from '../../../helpers/lead.helpers';
import { BankType, BankTypeName } from '../../../models/robot.model';
import { showToast } from '../../../services/toast.service';
import { editManyWithCpf } from '../../../store/features/leads/leads.api';
import { LeadTagSelector } from '../../../components/leadTagSelector/leadTagSelector';
import { LeadTag } from '../../../models/leadTag.model';
import { OptionItemType } from '../../../components/filter/filter.constants';
import { createLeadTag, getAllLeadTags } from '../../../store/features/leadTag/leadTag.slice';

export interface IModalSelectedLeads {
  selectedLeads: Array<string>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onUpdate: () => void;
}

export default function ModalEditLeadsByCPF({ selectedLeads, isOpen, onOpen, onClose, onUpdate }: IModalSelectedLeads) {

  const dispatch = useAppDispatch();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { loadingUpdate } = useAppSelector((state: RootState) => state.leads);
  const [cpfs, setCpfs] = useState<Array<string>>([]);
  const [selectedTag, setSelectedTag] = useState<OptionItemType>(null);
  const { tags, loading: loadingTags, error} = useAppSelector((state: RootState) => state.leadTag);

  const { handleSubmit, handleChange, values, setFieldValue, resetForm} = useFormik({
    enableReinitialize: true,
    initialValues: {
      cpfs: '',
      lastMessageStatus: 'NOTHING',
      lastProposalStatus: 'NOTHING',
      lastSimulationStatus: 'NOTHING',
      bank: '',
      tagId: null,
    },
    onSubmit: async (values) => {
      if (values?.lastSimulationStatus !== 'NOTHING' && !values?.bank) {
        showToast('error', 'Preencha o Banco');
        return;
      }
      const result = await dispatch(editManyWithCpf({
          cpfs,
          lastMessageStatus: values.lastMessageStatus as LeadSimulationStatus & 'NOTHING',
          lastProposalStatus: values.lastProposalStatus as LeadProposalStatus & 'NOTHING',
          lastSimulationStatus: values.lastSimulationStatus as LeadSimulationStatus & 'NOTHING',
          bank: values?.bank as BankType,
          tagId: selectedTag ? selectedTag.value : null,
        }),
      );
      console.log(result)
      if (result.payload) {
        showToast('success', `${result.payload} Leads editados!`);
        onClose();
        onUpdate();
      }
    },
  });

  useEffect(() => {
    resetForm();
    if (isOpen) {
      dispatch(getAllLeadTags());
      setSelectedTag(null);
    }
  }, [isOpen]);

  async function createNewTag(name: string) {
    const response: any = await dispatch(createLeadTag({
      name,
    }));
    if (response.payload) {
      setFieldValue('tag', response.payload);
    }
  }

  useEffect(() => {
    const newLeads: Array<string> = [];
    if (values?.cpfs && values?.cpfs.length) {
      const parsedCpfs = values?.cpfs.split(/[\n;,]/);
      parsedCpfs.forEach(l => {
        const cpf = isCPFValid(l || '');
        if (cpf) {
          newLeads.push(cpf);
        }
      });
    }
    setCpfs(newLeads);
  }, [values?.cpfs]);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={'2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar leads por CPF</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <HStack alignItems={'start'}>
              <VStack w={200} alignItems={'left'}>
                <Text as={'b'}>CPFs:</Text>
                <Textarea
                  onChange={handleChange('cpfs')}
                  value={values?.cpfs}
                  h={400}
                ></Textarea>
              </VStack>
              <VSeparator mt={5} mb={5} />
              <VStack alignItems={'start'} justifyContent={'flex-start'} gap={5}>
                <MiniStatistics
                  startContent={<IconBox
                        w='56px'
                        h='56px'
                        bg={'brand.500'}
                        icon={<Icon w='32px' h='32px' as={MdPerson} color={'secondaryGray.300'}/>}
                      />
                  }
                  name='CPFs válidos'
                  value={cpfs.length}
                />
                <HStack alignItems={'center'}>
                  <Text as={'b'} textAlign={'right'} w={200}>Última mensagem:</Text>
                  <Select w={200} placeholder='Última mensagem' onChange={handleChange('lastMessageStatus')}
                          value={values?.lastMessageStatus}>
                    <option value={'NOTHING'}>Não alterar</option>
                    {Object.values(LeadMessageStatus).map(s =>
                      <option value={s}>{messageStatusName[s]}</option>,
                    )}
                  </Select>
                </HStack>
                <HStack alignItems={'center'}>
                  <Text as={'b'} textAlign={'right'} w={200}>Última proposta:</Text>
                  <Select w={200} placeholder='Última proposta' onChange={handleChange('lastProposalStatus')}
                          value={values?.lastProposalStatus}>
                    <option value={'NOTHING'}>Não alterar</option>
                    {Object.values(LeadProposalStatus).map(s =>
                      <option value={s}>{proposalStatusName[s]}</option>,
                    )}
                  </Select>
                </HStack>
                <HStack alignItems={'center'}>
                  <Text as={'b'} textAlign={'right'} w={200}>Última simulação:</Text>
                  <Select w={200} placeholder='Última proposta' onChange={handleChange('lastSimulationStatus')}
                          value={values?.lastSimulationStatus}>
                    <option value={'NOTHING'}>Não alterar</option>
                    {Object.values(LeadSimulationStatus).filter(s => s !== LeadSimulationStatus.CREDIT).map(s =>
                      <option value={s}>{simulationStatusName[s]}</option>,
                    )}
                  </Select>
                </HStack>
                {values?.lastSimulationStatus !== 'NOTHING' ? <HStack alignItems={'center'}>
                  <Text as={'b'} textAlign={'right'} w={200}>Banco:</Text>
                  <FormControl isInvalid={values?.lastSimulationStatus !== 'NOTHING' && !values?.bank} w={200}>
                    <Select
                      w={200}
                      placeholder={'Selecione'}
                      onChange={(e) => setFieldValue('bank', e.target.value)}
                      value={values?.bank}
                    >
                      {Object.values(BankType).map(s =>
                        <option value={s}>{BankTypeName[s]}</option>,
                      )}
                    </Select>
                  </FormControl>
                </HStack> : null}
                <HStack alignItems={'center'} mt={5}>
                  <Text as={'b'} textAlign={'right'} w={200}>Tag:</Text>
                  <LeadTagSelector
                    options={tags.map((t: LeadTag) => {
                      return {
                        label: t.name,
                        value: t.id,
                        colorScheme: 'blue',
                      };
                    })}
                    label={'asd'}
                    value={selectedTag}
                    onChange={(v) => setSelectedTag(v)}
                    onNewTag={(n) => createNewTag(n)}
                  />
                </HStack>
              </VStack>
            </HStack>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancelar</Button>
          <ConfirmationButton onConfirm={handleSubmit}>
            <Button colorScheme='green' size={'md'} isLoading={loadingUpdate} isDisabled={!cpfs.length}>
              Editar
            </Button>
          </ConfirmationButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
