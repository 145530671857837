import { Box, Button, FormControl, HStack, IconButton, Spacer, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Select } from 'chakra-react-select';
import { FiFilter, FiSearch } from 'react-icons/fi';
import { MdOutlineRemoveCircleOutline } from 'react-icons/md';
import { FilterItemInputOptions } from './filterOptions';
import { FilterItemInputText } from './filterText';
import { FilterItemInputRangeNumber } from './filterNumberRange';
import { FilterItemInputRangeDate } from './filterDateRange';
import { AddedFilterType, FilterItem, InputRangeDateType, InputRangeNumberType } from './filter.constants';
import { showToast } from '../../services/toast.service';
import _ from 'lodash';

export type FilterProps = {
  options: { [key: string]: FilterItem };
  onApply: (v: Array<AddedFilterType>) => void;
  initialFilter?: Array<AddedFilterType>;
  externalFilter?: Array<AddedFilterType>,
  loading: boolean;
  onClick?: () => void;
  onExport?: (limit?: number) => void;
};

export default function Filter({ options, onApply, loading, onClick, initialFilter, onExport, externalFilter }: FilterProps) {

  const availableOptions: Array<FilterItem> = _.orderBy(Object.entries(options).map(([key, value]) => {
    return {
      label: value.label,
      type: value.type,
      key: value.key,
    };
  }), 'label');
  const [addedFilter, setAddedFilter] = useState<Array<AddedFilterType>>(initialFilter || []);

  useEffect(() => {
    // if (initialFilter?.length) {
      onApply(addedFilter.filter(f => f.key));
    // }
  }, [initialFilter]);

  useEffect(() => {
    if (externalFilter?.length) {
      setAddedFilter(externalFilter);
    }
  }, [externalFilter]);

  useEffect(() => {
    if (!addedFilter.length) {
      onApply([]);
    }
  }, [addedFilter]);

  function isSelected(key: string): boolean {
    return addedFilter.find(i => i.key === key) === undefined;
  }

  function addFilter() {
    if (onClick) {
      onClick();
    }
    setAddedFilter([...addedFilter, {
      key: undefined,
      values: [],
    }]);
  }

  function clearFilter() {
    setAddedFilter([]);
  }

  function set(key: string, index: number) {
    if (addedFilter[index].key !== key) {
      addedFilter[index].values = [];
      addedFilter[index].text = undefined;
      addedFilter[index].min = undefined;
      addedFilter[index].max = undefined;
      addedFilter[index].dateStart = undefined;
      addedFilter[index].dateEnd = undefined;
    }
    addedFilter[index].key = key;
    setAddedFilter([...addedFilter]);
  }

  function verifyErrors() {
    for (const filter of addedFilter) {
      if (filter.key) {
        switch (options[filter.key].type) {
          case 'options':
            if (!filter.values.length) {
              showToast('error', `Selecione corretamente: ${options[filter.key].label}`);
              return false;
            }
            break;
          case 'text':
            if (!filter.text) {
              showToast('error', `Preencha corretamente: ${options[filter.key].label}`);
              return false;
            }
            break;
          case 'rangeDate':
            if (!filter.dateStart || !filter.dateEnd) {
              showToast('error', `Preencha corretamente: ${options[filter.key].label}`);
              return false;
            }
            break;
          case 'rangeNumber':
            if (!filter.min || !filter.max) {
              showToast('error', `Preencha corretamente: ${options[filter.key].label}`);
              return false;
            }
            break;
        }
      } else {
        showToast('error', `Selecione os filtros`);
        return false;
      }
    }
    return true;
  }

  function search() {
    if (verifyErrors()) {
      onApply(addedFilter.filter(f => f.key));
    }
  }

  function removeFilter(key: string) {
    const indexToKemove = addedFilter.findIndex(i => i.key === key);
    addedFilter.splice(indexToKemove, 1);
    setAddedFilter([...addedFilter]);
  }

  function changeFilterOption(key: string, values?: Array<any>) {
    const index = addedFilter.findIndex(i => i.key === key);
    addedFilter[index].values = values;
    setAddedFilter([...addedFilter]);
  }

  function changeFilterText(key: string, value?: string) {
    const index = addedFilter.findIndex(i => i.key === key);
    addedFilter[index].text = value;
    setAddedFilter([...addedFilter]);
  }

  function changeFilterNumberRange(key: string, value?: InputRangeNumberType) {
    const index = addedFilter.findIndex(i => i.key === key);
    addedFilter[index].min = value.min;
    addedFilter[index].max = value.max;
    setAddedFilter([...addedFilter]);
  }

  function changeFilterDateRange(key: string, value?: InputRangeDateType) {
    const index = addedFilter.findIndex(i => i.key === key);
    addedFilter[index].dateStart = value.dateStart;
    addedFilter[index].dateEnd = value.dateEnd;
    setAddedFilter([...addedFilter]);
  }

  return (
      <Box w={'100%'}>
        <VStack spacing={2} align='stretch'>
          {addedFilter.map((item, index) => (
            <HStack gap={5} key={index}>
              <FormControl maxW={300}>
                <Select
                  size={'sm'}
                  name='colors'
                  colorScheme='purple'
                  options={availableOptions}
                  filterOption={(v) => isSelected(v.data.key)}
                  placeholder='Selecione um filtro...'
                  closeMenuOnSelect={true}
                  value={options[item.key]}
                  onChange={(v) => {
                    set(v.key, index);
                  }}
                  noOptionsMessage={f => 'Todos os filtros já foram selecionados'}
                />
              </FormControl>
              {item.key && options[item.key].type === 'options' && (
                <FilterItemInputOptions
                  onChange={(v) => changeFilterOption(item.key, v)}
                  options={options[item.key].options}
                  label={options[item.key].label}
                  value={item.values}
                  multi={true}
                />
              )}
              {item.key && options[item.key].type === 'text' && (
                <FilterItemInputText
                  onChange={(v) => changeFilterText(item.key, v)}
                  label={options[item.key].label}
                  value={item.text}
                />
              )}
              {item.key && options[item.key].type === 'rangeNumber' && (
                <FilterItemInputRangeNumber
                  onChange={(v) => changeFilterNumberRange(item.key, v)}
                  label={options[item.key].label}
                  value={{ min: item.min, max: item.max }}
                />
              )}
              {item.key && options[item.key].type === 'rangeDate' && (
                <FilterItemInputRangeDate
                  onChange={(v) => changeFilterDateRange(item.key, v)}
                  label={options[item.key].label}
                  value={{ dateStart: item.dateStart, dateEnd: item.dateEnd }}
                />
              )}
              <Spacer />
              <IconButton
                onClick={() => removeFilter(item.key)}
                colorScheme='red'
                aria-label='Search database'
                variant='link'
                fontSize={20}
                icon={<MdOutlineRemoveCircleOutline />}
              />
            </HStack>
          ))}
        </VStack>
        <HStack gap={5} mt={addedFilter.length > 0 ? 5 : 0}>
          {addedFilter.length < availableOptions.length && <>
            <Button ml={1} rightIcon={<FiFilter />} colorScheme='blue' variant='link' onClick={() => addFilter()}>
              {addedFilter.length === 0 ? 'Filtrar' : 'Novo filtro'}
            </Button>
          </>
          }
          <Spacer />
          {addedFilter.length > 0 && <>
            <Button colorScheme='red' variant='link' onClick={() => clearFilter()} size={'sm'}>
              Limpar
            </Button>
            <Button rightIcon={<FiSearch />} colorScheme='blue' onClick={() => search()} size={'sm'} isLoading={loading}>
              Buscar
            </Button>
          </>
          }
        </HStack>
      </Box>
  );
}
