import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import IntegrationsTable from './IntegrationsTable';
import { ModalIntegration } from './ModalIntegration';
import { getIntegrations } from '../../store/features/integration/integration.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IntegrationAuth, IntegrationAuthType } from '../../models/integrationAuth.model';
import { RootState } from '../../store/store';
import { AddIcon } from '@chakra-ui/icons';
import EmptyState from '../emptyState/emptyState.component';

interface Imodal {
  isOpen: boolean;
  onClose: () => void;
}

const tabsTypes = [IntegrationAuthType.MEU_CORBAN, IntegrationAuthType.BANK, IntegrationAuthType.HUGGY];

export function ModalIntegrations({ isOpen, onClose }: Imodal) {
  const { isOpen: modalIsOpen, onOpen: modalOnOpen, onClose: modalOnClose, onToggle: modalOnToggle } = useDisclosure();
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationAuth | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { integrations, loading, error, pagination } = useAppSelector((state: RootState) => state.integration);
  const [page, setPage] = useState(1);
  const [banks, setBanks] = useState<Array<IntegrationAuth>>([]);
  const [meuCorban, setMeuCorban] = useState<Array<IntegrationAuth>>([]);
  const [huggy, setHuggy] = useState<Array<IntegrationAuth>>([]);
  const [selectedType, setSelectedType] = useState<IntegrationAuthType>(IntegrationAuthType.MEU_CORBAN);

  useEffect(() => {
    dispatch(getIntegrations({ page }));
  }, [page]);

  useEffect(() => {
    setBanks(integrations.filter((i: IntegrationAuth) => i.type === IntegrationAuthType.BANK));
    setMeuCorban(integrations.filter((i: IntegrationAuth) => i.type === IntegrationAuthType.MEU_CORBAN));
    setHuggy(integrations.filter((i: IntegrationAuth) => i.type === IntegrationAuthType.HUGGY));
  }, [integrations]);

  useEffect(() => {
    if (selectedIntegration)
      modalOnToggle();
  }, [selectedIntegration]);

  function closeDrawerRobot(updated: boolean) {
    setSelectedIntegration(undefined);
    modalOnClose();
    if (updated) {
      dispatch(getIntegrations({ page }));
    }
  }

  function create() {
    modalOnToggle();
  }

  return (
    <Modal onClose={onClose} size={'5xl'} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Integrações</ModalHeader>
        <HStack mr={5}>
          <Spacer/>
          <Button rightIcon={<AddIcon />} colorScheme='blue' onClick={create} size={'sm'}>
            Novo
          </Button>
        </HStack>
        <ModalCloseButton />
        <ModalBody>
          <Tabs onChange={(index) => setSelectedType(tabsTypes[index])}>
            <TabList>
              <Tab>Meu Corban</Tab>
              <Tab>Bancos</Tab>
              <Tab>Huggy</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <EmptyState text={''} empty={meuCorban && !meuCorban.length} loading={loading} textFilter={''}>
                  <IntegrationsTable
                    tableData={meuCorban}
                    onSelect={(i) => setSelectedIntegration(i)}
                    onPageChange={setPage}
                    pagination={pagination}
                    loading={loading}
                  />
                </EmptyState>
              </TabPanel>
              <TabPanel>
                <EmptyState text={''} empty={banks && !banks.length} loading={loading} textFilter={''}>
                  <IntegrationsTable
                    tableData={banks}
                    onSelect={(i) => setSelectedIntegration(i)}
                    onPageChange={setPage}
                    pagination={pagination}
                    loading={loading}
                  />
                </EmptyState>
              </TabPanel>
              <TabPanel>
                <EmptyState text={''} empty={huggy && !huggy.length} loading={loading} textFilter={''}>
                  <IntegrationsTable
                    tableData={huggy}
                    onSelect={(i) => setSelectedIntegration(i)}
                    onPageChange={setPage}
                    pagination={pagination}
                    loading={loading}
                  />
                </EmptyState>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
      {isOpen ? <ModalIntegration integration={selectedIntegration} isOpen={modalIsOpen} selectedType={selectedType}
                                  onClose={(updated: boolean) => closeDrawerRobot(updated)} /> : null}
    </Modal>
  );
}
