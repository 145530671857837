import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Progress,
  SimpleGrid,
  Spacer,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { BankType, ProductType, RobotStatusType, RobotStatusTypeName } from '../../../models/robot.model';
import { TbHandStop } from 'react-icons/tb';
import { BiRefresh, BiTrashAlt } from 'react-icons/bi';
import { showToast } from '../../../services/toast.service';
import * as yup from 'yup';
import { InputText } from '../../../components/inputText/inputText.component';
import { InputNumber } from '../../../components/inputNumber/inputNumber.component';
import { FaPlay } from 'react-icons/fa6';
import { CreateLeadDto, statusColor } from '../../../models/lead.model';
import ConfirmationButton from 'components/buttons/RemoveConfirmation';
import { format } from 'date-fns';
import ModalWelfareCreateBatch from './ModalWelfareCreateBatch';
import AlertHelper from '../../../components/alertHelper/bankIcon.component';
import { getIntegrations } from '../../../store/features/integration/integration.slice';
import {
  IntegrationAuth,
  IntegrationAuthType,
  IntegrationAuthTypeTranslation,
} from '../../../models/integrationAuth.model';
import { WelfareRobot } from '../../../models/welfareRobot.model';
import {
  changeWelfareRobotStatus,
  clearWelfareImportationResult,
  createWelfareRobot,
  editWelfareRobot,
  exportWelfareBatch,
  getImportStatus,
  removeWelfareRobot,
} from '../../../store/features/welfareRobots/welfareRobots.slice';

interface IModalRobot {
  robot: WelfareRobot | undefined;
  isOpen: boolean;
  onClose: (updated: boolean) => void;
}

export function ModalWelfareRobot({ robot, isOpen, onClose }: IModalRobot) {
  const { isOpen: isOpenModalCreateBatch, onOpen: onOpenModalCreateBatch, onClose: onCloseModalCreateBatch } = useDisclosure();
  const { loadingUpdate, error, importPercent, loadingImporting, importResult } = useAppSelector((state: RootState) => state.welfareRobot);
  const [leadsToImport, setLeadsToImport] = useState<Array<CreateLeadDto>>([]);
  const dispatch = useAppDispatch();
  const [importId, setImportId] = useState<string>(null);
  const { integrations } = useAppSelector((state: RootState) => state.integration);
  const [integrationList, setIntegrationList] = useState<Array<{ id: string, label: string, tag: string }>>([]);
  console.log('ModalTypingRobot', robot?.id)
  useEffect(() => {
    dispatch(getIntegrations({ page: 1 }));
  }, []);
  useEffect(() => {
    if (integrations) {
      setIntegrationList(integrations.filter((i: IntegrationAuth) => i.type === IntegrationAuthType.MEU_CORBAN).map((i: IntegrationAuth) => {
        return { id: i.id, label: i.name, tag: IntegrationAuthTypeTranslation[i.type] };
      }));
    }
  }, [integrations]);
  const { handleSubmit, handleChange, values, resetForm, errors, setFieldValue, dirty } = useFormik({
    initialValues: {
      id: robot?.id,
      name: robot?.name,
      type: robot?.type || BankType.CREFISA,
      product: robot?.product || ProductType.BOLSA_FAMILIA,
      errorAttemps: robot?.errorAttemps || 10,
      retryErrorsAttempts: robot?.retryErrorsAttempts || 10,
      concurrency: robot?.concurrency || 1,
      batchLeads: [],
      token: '',
    },
    validationSchema: yup.object().shape({
      token: yup.string().required("Campo obrigatório"),
      limit: yup.number().max(50).min(1),
    }),
    onSubmit: async (values) => {
      console.log(values)
      if (!robot?.id) {
        const response: any = await dispatch(createWelfareRobot({ ...values } as WelfareRobot));
        console.log('response', response)
        if (response.error) {
          showToast('error', response.error.message);
        } else {
          setImportId(response.payload);
        }
      } else {
        const response: any = await dispatch(editWelfareRobot({ ...values } as WelfareRobot));
        if (response.error) {
          showToast('error', response.error.message);
        } else if (response.payload) {
          setImportId(response.payload);
        } else {
          onCreatedRobot();
        }
      }
    },
  });

  useEffect(() => {
    let importStatusInterval: string | number | NodeJS.Timeout;
    if (importId) {
      importStatusInterval = setInterval(() => {
        dispatch(getImportStatus(importId));
      }, 1000);
    } else {
      clearInterval(importStatusInterval);
    }
    return () => clearInterval(importStatusInterval);
  }, [importId]);

  useEffect(() => {
    setFieldValue('batchLeads', leadsToImport);
  }, [leadsToImport]);

  useEffect(() => {
    if (error) {
      showToast('error', error);
    }
  }, [error]);

  useEffect(() => {
    if (importResult) {
      setImportId(null);
      onCreatedRobot();
    }
  }, [importResult]);

  function onCreatedRobot() {
    showToast('success', 'Robo criada!');
    dispatch(clearWelfareImportationResult());
    closeModal(true);
  }

  async function remove() {
    const response = await dispatch(removeWelfareRobot({
      id: robot.id,
    }));
    if (response.payload) {
      showToast('success', 'Robô removido!');
      onClose(true);
    } else {
      showToast('error', error);
    }
  }

  async function updateStatus(status: RobotStatusType) {
    const response = await dispatch(changeWelfareRobotStatus({
      id: robot.id,
      status,
    }));
    if (response.payload) {
      showToast('success', `Alterado!`);
      onClose(true);
    } else {
      showToast('error', error);
    }
  }

  function closeModal(updated: boolean) {
    resetForm();
    onClose(updated);
  }

  async function baseDownload() {
    const result = await dispatch(exportWelfareBatch(robot?.id));
    if (result.payload) {
      const a = document.createElement('a');
      const blob = new Blob([result.payload as ArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `[wesend] Leads auxílio exportados ${format(new Date(), 'dd/MM/yyyy-HH:mm')}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  return (
    <Drawer
      size={'xl'}
      isOpen={isOpen}
      placement='right'
      onClose={() => closeModal(false)}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {robot?.name || 'Novo robô'}
          <Tag
            ml={5}
            borderRadius="full"
            variant="solid"
            size={'lg'}
            colorScheme={statusColor[robot?.status]}
          >
            <TagLabel>{RobotStatusTypeName[robot?.status]}</TagLabel>
          </Tag>
        </DrawerHeader>
        <DrawerBody>
          <Box borderWidth='1px' borderRadius='lg' p={5} overflow='hidden' mb={5}>
            <SimpleGrid columns={1} spacing={{ base: '20px', xl: '20px' }}>
              <ModalWelfareCreateBatch
                onClose={onCloseModalCreateBatch}
                onOpen={onOpenModalCreateBatch}
                isOpen={isOpenModalCreateBatch}
                onUpload={(leads) => setLeadsToImport(leads)}
              />
              <InputText
                label='Nome do robô'
                value={values?.name}
                onChange={handleChange('name')}
                error={errors.name}
              />
              <InputText
                label='Token autenticação'
                value={values?.token}
                onChange={handleChange('token')}
                error={errors.token}
              />
              <InputNumber
                label='Quantidade de tentativas por lead'
                value={values?.errorAttemps}
                onChange={(n) => setFieldValue('errorAttemps', n)}
                error={errors.errorAttemps}
                min={1}
                max={100}
              />
              <InputNumber
                label='Quantidade de retentativas por falha'
                value={values?.retryErrorsAttempts}
                onChange={(n) => setFieldValue('retryErrorsAttempts', n)}
                error={errors.retryErrorsAttempts}
                min={1}
                max={100}
              />
              <HStack alignItems={'center'}>
                <Text as={'b'} textAlign={'right'} w={200}>Base</Text>
                <Button colorScheme='blue' onClick={() => onOpenModalCreateBatch()}>Importar</Button>
                <Button colorScheme='green' onClick={baseDownload}>Exportar</Button>
                {!robot?.id && !leadsToImport.length ? <AlertHelper message="Importe uma base"></AlertHelper> : null }
                {!robot?.id && leadsToImport.length ?<AlertHelper component={<Text><b>{leadsToImport.length}</b> leads para simular</Text>}/> : null}
                {robot?.id && robot.batchLength ?<AlertHelper component={<Text><b>{leadsToImport.length || robot.batchLength}</b> leads para simular</Text>}/> : null}
              </HStack>
            </SimpleGrid>
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={'100%'} gap={2}>
            {loadingImporting ? <Flex w={'100%'} gap={2}>
                <Center>
                  <Progress colorScheme='blue' h='10px' w='300px'
                            value={importPercent} />
                  <Text ml={3}>{importPercent}%</Text>
                </Center>
              </Flex>
              : <>
                {robot?.id ?
                  <Button rightIcon={<BiRefresh />} colorScheme='yellow'
                          onClick={() => updateStatus(RobotStatusType.RUNNING)} size={'md'}
                          isLoading={loadingUpdate} isDisabled={dirty}>
                    Reiniciar
                  </Button> : null}
                {robot?.id && robot?.status === RobotStatusType.STOPPED ?
                  <Button rightIcon={<FaPlay />} colorScheme='green'
                          onClick={() => updateStatus(RobotStatusType.RUNNING)} size={'md'}
                          isLoading={loadingUpdate} isDisabled={dirty}>
                    Iniciar
                  </Button> : null}
                {robot?.id && robot?.status !== RobotStatusType.STOPPED ?
                  <Button rightIcon={<TbHandStop />} colorScheme='yellow'
                          onClick={() => updateStatus(RobotStatusType.STOPPED)} size={'md'}
                          isLoading={loadingUpdate} isDisabled={dirty}>
                    Parar
                  </Button> : null}
                {robot?.id ? <ConfirmationButton onConfirm={remove}>
                  <Button rightIcon={<BiTrashAlt />} colorScheme='red' size={'md'} isLoading={loadingUpdate}>
                    Remover
                  </Button>
                </ConfirmationButton> : null}
              </>}
            <Spacer />
            <HStack>
              <Button isDisabled={loadingUpdate || loadingImporting} colorScheme='gray' mr={3} onClick={() => onClose(false)}>
                Fechar
              </Button>
              <Spacer />
              <Button isLoading={loadingUpdate || loadingImporting} isDisabled={!robot?.id && !leadsToImport.length} colorScheme='blue' mr={3} onClick={() => handleSubmit()}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );


}
